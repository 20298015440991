import { ArrowForwardOutlined } from "@mui/icons-material";
import { Box, Chip, CircularProgress, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import * as Yup from "yup";
import { isObjectId } from "~/utils/yupIsObjectId";
export const Login = () => {
  const router = useRouter();
  const form = useFormik({
    validateOnMount: true,
    initialValues: {
      id: ""
    },
    validationSchema: Yup.object().shape({
      id: isObjectId.required()
    }),
    onSubmit: async (values, helpers) => {
      await router.replace(`/login/${values.id}`);
      helpers.resetForm();
    }
  });
  return <>
			<Stack sx={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 2
    }} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
				<img src="/images/waiting.png" width="300px" />
				<Box component="form" onSubmit={form.handleSubmit} data-sentry-element="Box" data-sentry-source-file="index.tsx">
					<Typography variant="body2" gutterBottom data-sentry-element="Typography" data-sentry-source-file="index.tsx">
						Tell us which Served venue are you
					</Typography>
					<TextField autoFocus label="Venue ID" sx={{
          width: "300px"
        }} name="id" onChange={form.handleChange} onBlur={form.handleBlur} value={form.values.id} error={!!form.errors.id} helperText={form.touched.id && form.errors.id} InputProps={{
          endAdornment: <InputAdornment position="end">
									{form.isSubmitting ? <CircularProgress size="24px" /> : <IconButton color="primary" type="submit" disabled={!form.isValid}>
											<ArrowForwardOutlined />
										</IconButton>}
								</InputAdornment>
        }} data-sentry-element="TextField" data-sentry-source-file="index.tsx" />
				</Box>

				<Stack direction="row" sx={{
        position: "fixed",
        bottom: 0,
        gap: 2,
        alignItems: "center",
        justifyContent: "center"
      }} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
					<img src="/images/favicon.png" width="50px" />

					<Chip label={[process.env.VERSION, process.env.ENV].filter(Boolean).join("-")} variant="filled" color="primary" data-sentry-element="Chip" data-sentry-source-file="index.tsx" />
				</Stack>
			</Stack>
		</>;
};